import Axios from './';
import { ListResult, User, Filter, UserCoupon } from '../models';

/**
* * get User List (https://yyt-dev.appicidea.com/doc/#/User/get_v1_user_)
+*/

export interface GetUserListParams extends Filter {
  phoneNumber?: string;
  memberType?: string;
  eRunid?: string;
  createdAtFrom?: string;
  createdAtTo?: string;
}

export const getUserList = async (params: GetUserListParams) => {
  const { data } = await Axios.get<ListResult<User>>('/user', { params });
  return data;
};

/**
* * add coupon to user (https://yyt-dev.appicidea.com/doc/#/Coupon/post_v1_coupon_)
+*/

export interface CreateUserCouponParams {
  discountPercentage: number | null | string;
  mininumPurchaseRequired: number | null;
  couponCode: string;
  endDate: string;
  description: string;
  canUseWithDiscount: boolean;
  amount: number | null | string;
  notificationTitle?: string;
  notificationContent?: string;
}

export const addCouponToUser = async (
  userid: string | number,
  params: CreateUserCouponParams,
) => {
  const { data } = await Axios.post<any>(`/user/${userid}/coupon`, params);
  return data;
};

// get user's coupons

export interface GetCouponsInUserParams extends Filter {}

export const getCouponsInUser = async (
  userid: string | number,
  params: GetCouponsInUserParams,
) => {
  const { data } = await Axios.get<ListResult<UserCoupon>>(
    `/user/${userid}/coupon`,
    { params },
  );
  return data;
};

// get user's profile

export const getOneUserProfile = async (userid: string | number) => {
  const { data } = await Axios.get<User>(`/user/${userid}`);
  return data;
};

/**
* * updaate user password (https://yyt-dev.appicidea.com/doc/#/User/post_v1_user__userid__resetPassword)
+*/

export const updateUserPassword = async (
  userid: number | string,
  password: string,
) => {
  const { data } = await Axios.post<any>(`/user/${userid}/resetPassword`, {
    password,
  });
  return data;
};

/**
* * create one user (https://yyt-dev.appicidea.com/doc/#/User/post_v1_user_)
+*/

export interface CreateUserParams {
  phoneNumber: string;
  password: string;
  monthOfBirth: string | number;
  fullName: string;
}

export const createUser = async (params: CreateUserParams) => {
  const { data } = await Axios.post<any>('/user', params);
  return data;
};

/**
* * delete one coupon in user (https://yyt-dev.appicidea.com/doc/#/User/delete_v1_user__userid__coupon__usercouponid_)
+*/

export const deleteOneCouponInUser = async (
  userid: string | number,
  usercouponid: string | number,
) => {
  const { data } = await Axios.delete<any>(
    `/user/${userid}/coupon/${usercouponid}`,
  );
  return data;
};
