import { TimeStamp } from './Common';

export interface DiscountedPrice {
  promotionid?: number | string | null;
  sellingPrice: number | null;
  startTime: string | null;
  endTime: string | null;
}

export interface ProductDiscountRules extends TimeStamp {
  DiscountProduct: null | Products;
  Gift: null | Products;
  couponCode: null | string;
  discountPrice: null | number;
  discountProductid: null | number;
  endDateTime: null | string;
  giftid: null | number;
  productDiscountRuleid: number;
  productid: number;
  startDateTime: null | string;
  status: string;
  type: ProductDiscountType;
  description: null | string;
}

export interface Products extends TimeStamp {
  AvailableProductDiscountRules: ProductDiscountRules[];
  actualPrice: number;
  productid: number;
  productBarcode: string;
  description: string;
  productName: string;
  shortName: string;
  labelName: string;
  sellingPrice: number;
  discountedPrices: DiscountedPrice[];
  onlineShopPrice: number;
  images: null | string[];
  categoryky: string;
  category: string;
  categoryid: string;
  highlight: string;
  unit?: string;
  unitUomCode?: string;
  placeOfOrigin: string;
  weight: number;
  stockLevel: number;
  hideInSearch: boolean;
  video: string | null;
  weeklySellQuantity: number;
  ranking?: number;
  purchaseCount: number;
  adminPurchaseCount: number;
}

export interface ERunProducts {
  barcode: string;
  productCode: string;
  productName: string;
  shortName: string;
  labelName: string;
  unitCode: string;
  unitUomCode?: string;
  isFloatingPrice: string;
  sellingPrice: string;
  stockLevel: number;
  description: string;
  code: string;
}

export interface SearchProducts {
  productid: number;
  productBarcode: string;
  productName: string;
  shortName: string;
  labelName: string;
  sellingPrice: number;
  actualPrice: number;
  images: string[] | null;
  category: string;
  description: string;
  highlight: string;
  unit?: string;
  unitUomCode?: string;
  placeOfOrigin: string;
  weight: number;
  createdAt: string;
  tags?: string[];
  discountedPrices: DiscountedPrice[];
  AvailableProductDiscountRules: ProductDiscountRules[];
  hideInSearch: boolean;
  weeklySellQuantity?: number;
  ranking?: number;
}

export interface Unit {
  ky: string;
  name: string;
}

export enum PlaceOfOrigin {
  'HK' = '香港',
  'CN' = '中國',
  'JP' = '日本',
  'TH' = '泰國',
  'US' = '美國',
  'ZA' = '非洲',
}

export const SEARCH_PRICE_OPTIONS = [
  {
    value: '',
    label: '所有價格',
  },
  {
    value: '0-50000',
    label: 'HK$ 500 以下',
  },
  {
    value: '50000-100000',
    label: 'HK$ 500 - 1000',
  },
  {
    value: '100000-150000',
    label: 'HK$ 1000 - 1500',
  },
  {
    value: '150000-200000',
    label: 'HK$ 1500 - 2000',
  },
  {
    value: '200000-250000',
    label: 'HK$ 2000 - 2500',
  },
  {
    value: '250000-300000',
    label: 'HK$ 2500 - 3000',
  },
  {
    value: '300000',
    label: 'HK$ 3000 以上',
  },
];

export const SEARCH_ORDER_OPTIONS = [
  {
    value: '',
    label: '預設排序',
  },
  {
    value: 'latest',
    label: '最新產品',
  },
  {
    value: 'popularity',
    label: '人氣產品',
  },
  {
    value: 'priceLowToHigh',
    label: '價格由低至高',
  },
  {
    value: 'priceHighToLow',
    label: '價格由高至低',
  },
  {
    value: 'ranking',
    label: 'Ranking',
  },
];

export type ProductDiscountType = 'A1' | 'A2' | 'A5';
