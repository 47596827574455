import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import InputField from '../InputField';
import { HTMLLinkType } from 'app/models';
import { getLinkName } from 'app/helpers/FormHelper';

const LinkSchema = Yup.object().shape({
  link: Yup.string()
    .test('CheckLinkType', '格式有誤', (link, context) => {
      const webReg = /^https:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
      const emailReg = /^[^\s@:%.+~#=$]+@[^\s@]+\.[^\s@:%.+~#=$]{2,5}$/;
      const telReg = /^(\(\+[0-9]{3}\))?\s*?[2-9]{1}[\d]{7}$/;

      const regex = new RegExp(
        context.parent.linkType === 'link'
          ? webReg
          : context.parent.linkType === 'email'
          ? emailReg
          : telReg,
      );

      if (link && context.parent.linkType === 'link') {
        if (regex.test(link)) {
          return true;
        } else {
          return context.createError({ message: 'URL 格式有誤' });
        }
      } else if (link && context.parent.linkType === 'email') {
        if (regex.test(link)) {
          return true;
        } else {
          return context.createError({ message: '電郵格式有誤' });
        }
      } else if (link && context.parent.linkType === 'tel') {
        if (regex.test(link)) {
          return true;
        } else {
          return context.createError({ message: '電話格式有誤' });
        }
      }
      return true;
    })
    .required('必填項目'),
  linkType: Yup.string().nullable(),
});

interface FormItem {
  link: string;
  linkType?: HTMLLinkType;
}

const FORM_ITEM = {
  link: '',
  linkType: 'link' as HTMLLinkType,
};

interface Props {
  linkOnSubmit: (value: FormItem) => void;
  linkType: HTMLLinkType;
}

const LinkForm = ({ linkOnSubmit, linkType }: Props) => {
  const [form, setForm] = useState<FormItem>(FORM_ITEM);

  const initForm = useCallback(() => {
    setForm({
      link: '',
      linkType: linkType as HTMLLinkType,
    });
  }, [linkType]);

  useEffect(() => {
    initForm();
  }, [initForm]);

  const onSubmit = values => {
    const { link } = values;

    switch (linkType) {
      case 'link':
        linkOnSubmit({ link: `https://${link}` });
        break;
      case 'email':
        linkOnSubmit({ link: `mailto:${link}` });
        break;
      case 'tel':
        linkOnSubmit({ link: `tel:${link}` });
        break;
    }
  };

  return (
    <Formik
      initialValues={form}
      validationSchema={LinkSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, errors, setValues }) => (
        <Form className="form-horizontal">
          <>
            <div className="mb-3">
              <InputField
                name="link"
                label={getLinkName(linkType)}
                placeholder={`請輸入有效的${getLinkName(linkType)}格式`}
              />
            </div>
            <div className="mt-3 d-grid">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
              >
                確定
              </button>
            </div>
          </>
        </Form>
      )}
    </Formik>
  );
};

export default LinkForm;
