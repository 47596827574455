import React, { useEffect, useRef } from 'react';
import { useField, FieldHookConfig } from 'formik';
import clsx from 'clsx';

import { Label, InputGroup } from 'reactstrap';
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import './form.scss';

interface OtherProps {
  label?: string;
  placeholder?: string;
  minDate?: Date | Date[] | null | string;
  onChange?: (value) => void;
  disabled?: boolean;
  mode?: 'single' | 'multiple' | 'range';
  enableTimeInCAL?: boolean;
}

const DateField = (props: OtherProps & FieldHookConfig<Date[] | null>) => {
  const dateRef = useRef<any>();
  const [field, meta, helpers] = useField(props);
  const {
    label,
    placeholder,
    minDate,
    onChange,
    disabled,
    mode,
    enableTimeInCAL,
  } = props;

  useEffect(() => {
    if (disabled) {
      dateRef.current.flatpickr.altInput.disabled = true;
    } else {
      dateRef.current.flatpickr.altInput.disabled = false;
    }
  }, [disabled]);

  return (
    <div
      className={clsx(
        'mb-2',
        'form-group',
        meta.touched && meta.error && 'text-danger',
      )}
      style={{ height: 85 }}
    >
      {label ? <Label>{props.label}</Label> : null}
      <InputGroup className={disabled ? '' : 'date-picker-isEable'}>
        <Flatpickr
          ref={dateRef}
          className={
            disabled ? 'form-control d-block' : 'form-control d-block bg-white'
          }
          placeholder={placeholder || '日期'}
          options={{
            mode: mode ? mode : 'single',
            altInput: true,
            minDate: minDate ? minDate[0] : null,
            altFormat: enableTimeInCAL ? 'Y-m-d H:i' : 'Y-m-d',
            dateFormat: enableTimeInCAL ? 'Y-m-d H:i' : 'Y-m-d',
            enableTime: enableTimeInCAL ? true : false,
          }}
          value={field.value}
          onChange={date => {
            if (onChange) {
              onChange(date);
            } else {
              if (date[0]) {
                helpers.setValue(date);
              } else {
                helpers.setValue(null);
              }
            }
          }}
        />
      </InputGroup>
      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
    </div>
  );
};

export default DateField;
