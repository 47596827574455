import { TimeStamp } from './Common';

export type MessageStausType = typeof MessageStatusStr[number];
export const MessageStatusStr = ['Pending', 'Completed'];
export enum MessageStatusOption {
  'Pending' = '等待中',
  'Completed' = '已讀取',
}

export interface Message extends TimeStamp {
  contactName: string;
  email: string;
  message: string;
  messageid: number;
  phone: string;
  status: MessageStausType;
  userid: number;
  isReplyRequired: boolean;
}
