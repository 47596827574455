import { TimeStamp } from 'app/models';
import {
  RegionType,
  HKType,
  KLType,
  NTType,
  Products,
  StripeLog,
  User,
} from 'app/models';
import { Coupon } from './Coupon';
import { Shop } from './Shop';

export const OrderPaymentStatusStr = [
  '',
  'Pending',
  'Success',
  'Canceled',
  'Failed',
  'InProgress',
  'Refunded',
] as const;

export type OrderPaymentStatusType = typeof OrderPaymentStatusStr[number];

export const OrderTypeStr = ['', '店鋪取貨', '送貨上門'] as const;

export type OrderType = typeof OrderTypeStr[number];

export interface OrderProductDiscount extends TimeStamp {
  orderProductDiscountid: number;
  orderProductid: number;
  discountProductid: number | null;
  giftid: number | null;
  type: string;
  numberOfProduct: number;
  discountPrice: number;
  description: null | string;
  Gift: Products | null;
  DiscountProduct: Products | null;
}

export interface OrderProducts extends TimeStamp {
  Product: Products;
  numberOfProduct: number;
  orderProductid: number;
  orderid: number;
  pricePerItem: number;
  productid: number;
  shoppingCartid: number;
  totalAmount: number;
  userid: number;
  OrderProductDiscounts: OrderProductDiscount[];
}

export type PaymentMethodType = 'fps' | 'payme' | 'stripe';

export interface Order extends TimeStamp {
  erunRef: string;
  OrderGlobalRules: OrderProductDiscount[];
  OrderProducts: OrderProducts[];
  ShippingLogs: StripeLog[];
  Shop: Shop | null;
  User: null | User;
  orderid: number;
  userid: number;
  shopid: null | number;
  userCouponid: number;
  clientRemarks: null | string;
  adminRemarks: null | string;
  images: string[] | null;
  paymentMethod: PaymentMethodType;
  paymentStatus: OrderPaymentStatusType;
  pickupDate: string;
  shippingStatus: ShippingStatusType;
  trackingNos: null | string;
  expectedDeliveryDate: null | string;
  amount: number;
  discount: number;
  transactionFee: number;
  shippingFee: number;
  paymentDate: null | string;
  stripeReference: string;
  region: null | RegionType;
  district: null | HKType | KLType | NTType;
  street: null | string;
  building: null | string;
  floor: null | string;
  room: null | string;
  consigneeName: null | string;
  consigneePhoneNumber: null | string;
  type: '店鋪取貨' | '送貨上門';
  total: number;
  UserCoupon: Coupon;
  pointEarned: number | null;
  pointUsed: number | null;
  isWeb: boolean;
}

export enum DeliverOptions {
  'Preparing' = '準備中',
  'ReadyToShip' = '待發貨',
  'Shipping' = '送貨中',
  'Shipped' = '已送達',
}

export const DeliverOptionsStr = [
  'Preparing',
  'ReadyToShip',
  'Shipping',
  'Shipped',
];

export const DELIVER_OPTIONS = [
  { value: 'ReadyToShip', label: '待發貨' },
  { value: 'Shipping', label: '送貨中' },
  { value: 'Shipped', label: '已送達' },
];

export enum PickupOptions {
  'Preparing' = '準備中',
  'Packed' = '已執貨',
  'ReadyForPickup' = '可取貨',
  'Pickuped' = '已取貨',
  'Expired' = '未取貨',
}

export const PickupOptionsStr = [
  'Preparing',
  'Packed',
  'ReadyForPickup',
  'Pickuped',
  'Expired',
];

export const PICKUP_OPTIONS = [
  { value: 'Preparing', label: '準備中' },
  { value: 'Packed', label: '已執貨' },
  { value: 'ReadyForPickup', label: '可取貨' },
  { value: 'Pickuped', label: '已取貨' },
  { value: 'Expired', label: '未取貨' },
];

export type ShippingStatusType =
  | ''
  | 'Preparing'
  | 'ReadyToShip'
  | 'Shipping'
  | 'Shipped'
  | 'Packed'
  | 'ReadyForPickup'
  | 'Pickuped'
  | 'Expired';

export interface OrderSaleResponse {
  count: number;
  total: number;
}
