import { Redirect } from 'react-router-dom';

import { LogoutPage, LoginPage } from 'app/pages/Authentication';
import { AdminListPage, AdminFormPage } from 'app/pages/AdminPage';
import { UserFormPage, UserListPage } from 'app/pages/UserPage';
import { HomePage } from 'app/pages/HomePage/Loadable';
import {
  ShopFormPage,
  ShopListPage,
  ShopSortingPage,
} from 'app/pages/ShopPage';
import { NotFoundPage } from 'app/pages/NotFoundPage/Loadable';
import { MessageListPage } from 'app/pages/MessagePage';
import {
  ProductListPage,
  ProductFormPage,
  TopProductListPage,
} from 'app/pages/ProductPage';
import {
  CategoryFormPage,
  CategoryListPage,
  CategorySortPage,
} from 'app/pages/CategoryPage';
import {
  PromotionEditFormPage,
  PromotionFormPage,
  PromotionListPage,
} from 'app/pages/PromotionPage';
import { CouponListPage, CouponFormPage } from 'app/pages/CouponPage';
import { OrderListPage, OrderDetailPage } from 'app/pages/OrderPage';
import { ShippingListPage, ShippingDetailPage } from 'app/pages/ShippingPage';
import { EmptyPage } from 'app/pages/EmptyPage/Loadable';
import {
  NotificationFormPage,
  NotificationListPage,
} from 'app/pages/NotificationPage';
import { GiftFormPage, GiftListPage } from 'app/pages/GiftPage';
import { MaintenanceFormPage } from 'app/pages/MaintenancePage/MaintenanceFormPage';
import { UserDetailPage } from 'app/pages/UserPage/UserDetailPage';
import {
  VideoFormPage,
  VideoListPage,
  VideoSectionFormPage,
  VideoSectionListPage,
  VideoSectionSortPage,
} from 'app/pages/VideoPage';
import {
  BannerFormPage,
  BannerListPage,
  BannerSortPage,
  PopupBannerFormPage,
} from 'app/pages/BannerPage';
import { TakeDownDetailPage, TakeDownListPage } from 'app/pages/TakeDownPage';

interface Route {
  path: string;
  component?: any;
  exact?: boolean;
}
const userRoutes: Route[] = [
  // admin
  { path: '/admins', component: AdminListPage },
  { path: '/admins/new', component: AdminFormPage, exact: true },
  { path: '/admins/edit/:adminid', component: AdminFormPage, exact: true },
  {
    path: '/admins/edit/:adminid/:password',
    component: AdminFormPage,
    exact: true,
  },
  { path: '/users', component: UserListPage },
  { path: '/users/detail/:userid', component: UserDetailPage },
  { path: '/users/new', component: UserFormPage },
  { path: '/shops', component: ShopListPage },
  { path: '/shops/new', component: ShopFormPage },
  { path: '/shops/edit/:shopid', component: ShopFormPage },
  { path: '/shops-ordering', component: ShopSortingPage },
  { path: '/messages', component: MessageListPage },
  { path: '/products', component: ProductListPage },
  { path: '/products-top-5', component: TopProductListPage },
  { path: '/products/new', component: ProductFormPage },
  { path: '/products/edit/:productid', component: ProductFormPage },
  { path: '/category', component: CategoryListPage },
  { path: '/category/new', component: CategoryFormPage },
  { path: '/category/edit/:categoryid', component: CategoryFormPage },
  { path: '/category-ordering', component: CategorySortPage },
  { path: '/promotion', component: PromotionListPage },
  { path: '/promotion/new', component: PromotionFormPage },
  { path: '/promotion/edit/:promotionid', component: PromotionEditFormPage },
  { path: '/orders', component: OrderListPage },
  { path: '/orders/detail/:orderid', component: OrderDetailPage },
  { path: '/shipping/:type', component: ShippingListPage },
  { path: '/shipping/:type/detail/:orderid', component: ShippingDetailPage },
  { path: '/coupon', component: CouponListPage },
  { path: '/coupon/new', component: CouponFormPage },
  { path: '/coupon/edit/:couponid', component: CouponFormPage },
  { path: '/notification', component: NotificationListPage },
  { path: '/notification/new', component: NotificationFormPage },
  { path: '/gifts', component: GiftListPage },
  { path: '/gifts/new', component: GiftFormPage },
  { path: '/gifts/edit/:globalDiscountRuleid', component: GiftFormPage },
  { path: '/video', component: VideoListPage },
  { path: '/video/new', component: VideoFormPage },
  { path: '/video/edit/:videoTutorialId', component: VideoFormPage },
  { path: '/video-section', component: VideoSectionListPage },
  { path: '/video-section-sort', component: VideoSectionSortPage },
  { path: '/video-section/new', component: VideoSectionFormPage },
  {
    path: '/video-section/edit/:videoTutorialSectionId',
    component: VideoSectionFormPage,
  },
  { path: '/banners', component: BannerListPage },
  { path: '/banners-sort', component: BannerSortPage },
  { path: '/banners/new', component: BannerFormPage },
  { path: '/banners/edit/:bannerId', component: BannerFormPage },
  { path: '/popup', component: PopupBannerFormPage },
  {
    path: '/take-down',
    component: TakeDownListPage,
  },
  {
    path: '/take-down/detail/:takedownId',
    component: TakeDownDetailPage,
  },
  { path: '/maintenance', component: MaintenanceFormPage },
  { path: '/', component: HomePage },
  { path: '/notFound', component: NotFoundPage },
  { path: '*', component: () => <Redirect to="/notFound" /> },
];

const authRoutes: Route[] = [
  { path: '/logout', component: LogoutPage },
  { path: '/login', component: LoginPage },
  { path: '/mobileapp', component: EmptyPage },
  { path: '/notFound', component: NotFoundPage },
];

export { userRoutes, authRoutes };
