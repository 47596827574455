import React from 'react';
import moment from 'moment';
import { CouponStatus, UserCoupon } from 'app/models';
import { convertToClientPrice, formatPrice } from 'app/helpers/CommonHelper';
import { UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';

const UserDetailColumns = deleteOnClick => [
  {
    text: '優惠碼',
    dataField: 'couponCode',
    sort: false,
    formatter: (userCoupon: UserCoupon) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">{userCoupon.couponCode || '-'}</div>
        </h5>
      </>
    ),
  },
  {
    text: '拆扣',
    datafield: 'discountPercentage',
    sort: false,
    formatter: (userCoupon: UserCoupon) => (
      <>
        <h5 className="font-size-14 mb-1">
          {userCoupon.amount ? (
            <div className="text-dark">
              {formatPrice(convertToClientPrice(userCoupon.amount))}
            </div>
          ) : null}
          {userCoupon.discountPercentage ? (
            <div className="text-dark">
              {(100 - userCoupon.discountPercentage * 100) / 10}折
            </div>
          ) : null}
        </h5>
      </>
    ),
  },
  {
    text: '最低消費',
    datafield: 'discountPercentage',
    sort: false,
    formatter: (userCoupon: UserCoupon) => (
      <>
        <h5 className="font-size-14 mb-1">
          {userCoupon.mininumPurchaseRequired ? (
            <div className="text-dark">
              ${convertToClientPrice(userCoupon.mininumPurchaseRequired)}
            </div>
          ) : (
            <div className="text-dark">-</div>
          )}
        </h5>
      </>
    ),
  },
  {
    text: '可與其他折扣共用',
    datafield: 'canUseWithDiscount',
    sort: false,
    formatter: (userCoupon: UserCoupon) => (
      <>
        <h5 className="font-size-14 mb-1">
          {userCoupon.canUseWithDiscount ? (
            <div className="text-dark">是</div>
          ) : (
            <div className="text-muted">否</div>
          )}
        </h5>
      </>
    ),
  },
  {
    text: '備註',
    datafield: 'description',
    sort: false,
    formatter: (userCoupon: UserCoupon) => (
      <>
        <h5 className="font-size-14 mb-1">{userCoupon.description || '-'}</h5>
      </>
    ),
  },
  {
    text: '系統訊息',
    datafield: 'remarks',
    sort: false,
    formatter: (userCoupon: UserCoupon) => (
      <>
        <h5 className="font-size-14 mb-1">{userCoupon.remarks || '-'}</h5>
      </>
    ),
  },
  {
    text: '有效日期',
    datafield: 'startDate',
    sort: false,
    formatter: (userCoupon: UserCoupon) => (
      <>
        <h5 className="font-size-14 mb-1">
          {`開始: ${
            userCoupon.startDate
              ? moment(userCoupon.startDate).format('DD-MM-YYYY')
              : '-'
          }`}
        </h5>
        <h5 className="font-size-14 mb-1">
          {`結束: ${
            userCoupon.endDate
              ? moment(userCoupon.endDate).format('DD-MM-YYYY')
              : '-'
          }`}
        </h5>
      </>
    ),
  },
  {
    text: '狀態',
    datafield: 'status',
    sort: false,
    formatter: (userCoupon: UserCoupon) => (
      <>
        <h5 className="font-size-14 mb-1">{CouponStatus[userCoupon.status]}</h5>
      </>
    ),
  },
  {
    text: '創建日期',
    dataField: 'createdAt',
    sort: false,
    formatter: (userCoupon: UserCoupon) => (
      <>
        <h5 className="font-size-14 mb-1">
          <div className="text-dark">
            {moment(userCoupon.createdAt).format('DD-MM-YYYY')}
          </div>
        </h5>
      </>
    ),
  },
  {
    text: '訂單號碼',
    datafield: 'status',
    sort: false,
    formatter: (userCoupon: UserCoupon) => (
      <>
        <ul className="list-inline contact-links mb-0 d-flex flex-row justify-contetn-end">
          {userCoupon?.Orders.map(order => (
            <li
              key={order.orderid}
              className="list-inline-item px-2 cursor-pointer text-muted"
            >
              <Link
                to={`/orders/detail/${order?.orderid}`}
                id={'ordersdetail' + order.orderid}
                // className="font-size-14"
              >
                <h5 className="font-size-14 mb-1">{order.orderid}</h5>
                <UncontrolledTooltip
                  placement="top"
                  target={'ordersdetail' + order.orderid}
                >
                  {`查看訂單 ${order?.orderid}`}
                </UncontrolledTooltip>
              </Link>
            </li>
          ))}
        </ul>
      </>
    ),
  },

  {
    dataField: 'menu',
    isDummyField: true,
    text: '',
    formatter: (userCoupon: UserCoupon) => (
      <ul className="list-inline font-size-20 contact-links mb-0 d-flex flex-row justify-contetn-end">
        <li
          className="list-inline-item px-2 cursor-pointer text-muted"
          style={{ opacity: userCoupon.Orders.length ? 0.5 : 1 }}
          id={'delete' + userCoupon.userCouponid}
          onClick={() =>
            userCoupon.Orders.length ? null : deleteOnClick(userCoupon)
          }
        >
          <i className="bx bx-trash" />
          <UncontrolledTooltip
            placement="top"
            target={'delete' + userCoupon.userCouponid}
          >
            {userCoupon.Orders.length ? '優惠券已使用' : '刪除優惠券'}
          </UncontrolledTooltip>
        </li>
      </ul>
    ),
  },
];

export default UserDetailColumns;
