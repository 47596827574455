/**
 *
 * Asynchronously loads the component for PopupBannerFormPage
 *
 */
import { lazyLoad } from 'utils/loadable';

export const PopupBannerFormPage = lazyLoad(
  () => import('./index'),
  module => module.PopupBannerFormPage,
);
