import React from 'react';
import { Modal } from 'reactstrap';

interface Props {
  size?: 'lg' | 'sm' | 'md';
  visible: boolean;
  title: string;
  onClose: () => void;
  onConfirm?: () => void;
  loading?: boolean;
  children: React.ReactChild;
  confirmBtnText?: string;
  btnType?:
    | 'danger'
    | 'success'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'warning'
    | 'dark'
    | 'light';
}

function Dialog({
  visible,
  title,
  onClose,
  loading,
  onConfirm,
  children,
  confirmBtnText,
  btnType,
  size,
}: Props) {
  return (
    <Modal isOpen={visible} scrollable={true} size={size}>
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          {title}
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={onClose}
          disabled={loading ? true : false}
        ></button>
      </div>
      <div className="modal-body">{children}</div>
      {onConfirm ? (
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={onClose}
            disabled={loading ? true : false}
          >
            取消
          </button>
          <button
            type="button"
            className={`btn btn-${btnType ? btnType : 'danger'}`}
            onClick={onConfirm}
            disabled={loading ? true : false}
          >
            {loading ? (
              <i className="bx bx-loader-circle bx-spin" />
            ) : (
              `${confirmBtnText ? confirmBtnText : '確定'}`
            )}
          </button>
        </div>
      ) : null}
    </Modal>
  );
}

export default Dialog;
