import React, { useEffect } from 'react';
import { useField, FieldHookConfig } from 'formik';
import clsx from 'clsx';

import { Label, Input } from 'reactstrap';

type InputType =
  | 'textarea'
  | 'button'
  | 'checkbox'
  | 'color'
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'file'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'password'
  | 'radio'
  | 'range'
  | 'reset'
  | 'search'
  | 'submit'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week';

interface OtherProps {
  label?: string;
  horizontal?: boolean;
  type?: InputType;
  placeholder?: string;
  disabled?: boolean;
  watch?: () => void;
}

const InputField = (props: OtherProps & FieldHookConfig<string>) => {
  const [field, meta] = useField(props);
  const { label, type, placeholder, disabled, watch } = props;
  useEffect(() => {
    if (watch) {
      watch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);
  return (
    <div
      className={clsx(
        'mb-2',
        'form-group',
        meta.touched && meta.error && 'text-danger',
      )}
      //style={type === 'textarea' ? { minHeight: 120 } : { height: 85 }}
    >
      {label ? <Label>{props.label}</Label> : null}
      <Input
        className="form-control"
        {...field}
        placeholder={placeholder ? placeholder : ''}
        type={type ? type : 'text'}
        autoComplete="new-password"
        style={type === 'textarea' ? { minHeight: 85 } : { height: 38 }}
        disabled={disabled}
      />
      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
    </div>
  );
};

export default InputField;
