import { PAGE_SIZE } from 'app/config';
import React from 'react';
import { Table } from 'reactstrap';
import './table.scss';

interface Props {
  columns: any[];
  data: any[];
  keyField: string;
  noWrap?: boolean;
  onChceck: (item: any) => void;
  selected: Record<string | number, any>;
  checkAll: () => void;
}

function SelectableTable({
  columns,
  data,
  keyField,
  noWrap,
  onChceck,
  selected,
  checkAll,
}: Props) {
  return (
    <Table className="table align-middle table-nowrap table-hover">
      <thead className="table-light">
        <tr>
          <th onClick={() => checkAll()}>
            {(Object.keys(selected).length === PAGE_SIZE ||
              Object.keys(selected).length === data.length) &&
            Object.keys(selected).length > 0 ? (
              <i className="bx bx-check-square check-icon active" />
            ) : (
              <i className="bx bx-square check-icon" />
            )}
          </th>
          {columns.map(column => (
            <th key={column.text}>{column.text}</th>
          ))}
        </tr>
      </thead>
      {data.length > 0 ? (
        <tbody>
          {data.map(item => {
            return (
              <tr key={item[keyField]}>
                <td
                  style={noWrap ? { whiteSpace: 'pre-line' } : {}}
                  onClick={() => onChceck(item)}
                >
                  {selected[item[keyField]] ? (
                    <i className="bx bx-check-square check-icon active" />
                  ) : (
                    <i className="bx bx-square check-icon" />
                  )}
                </td>
                {columns.map(column => {
                  if (column.formatter) {
                    return (
                      <td
                        key={column.dataField + item[keyField]}
                        style={noWrap ? { whiteSpace: 'pre-line' } : {}}
                      >
                        {column.formatter(item)}
                      </td>
                    );
                  }
                  return (
                    <td key={column.dataField + item[keyField]}>
                      {item[column.dataField]}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      ) : null}
    </Table>
  );
}

export default SelectableTable;
