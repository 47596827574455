/**
 *
 * Asynchronously loads the component for NotificationFormPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const NotificationFormPage = lazyLoad(
  () => import('./index'),
  module => module.NotificationFormPage,
);
