import React from 'react';
import { useField, FieldHookConfig } from 'formik';
import clsx from 'clsx';
import { Label, Input } from 'reactstrap';
import { OptionItems } from 'app/models';
import { AsyncPaginate } from 'react-select-async-paginate';

interface OtherProps {
  label: string;
  placeholder?: string;
  cacheOptions?: boolean;
  inputValue?: string;
  defaultOptions?: OptionItems[];
  loadOptions: (search: string, loadedOptions: any[]) => any;
  selectOnChange?: (value: any) => void;
  onInputChange?: (e) => void;
  disabled?: boolean;
  isClearable?: boolean;
  menuPlacement?: 'auto' | 'bottom' | 'top';
}

const AsyncSelectField = (props: OtherProps & FieldHookConfig<any>) => {
  const [field, meta, helpers] = useField(props);
  const {
    label,
    placeholder,
    cacheOptions,
    loadOptions,
    selectOnChange,
    disabled,
    isClearable,
    menuPlacement,
    defaultOptions,
  } = props;
  // const rendervalue = () => {
  //   const index = _.findIndex(options, option => option.value === field.value);
  //   if (index > -1) {
  //     return options[index];
  //   }
  //   return null;
  // };
  return (
    <div
      className={clsx(
        'mb-2',
        'form-group',
        meta.touched && meta.error && 'text-danger',
      )}
      style={{ height: 85 }}
    >
      {label ? <Label>{props.label}</Label> : null}
      {disabled ? (
        <Input
          className="form-control"
          placeholder={placeholder ? placeholder : ''}
          type={'text'}
          autoComplete="new-password"
          style={{ height: 38 }}
          disabled={disabled}
          // value={rendervalue() ? rendervalue()!.label : ''}
        />
      ) : (
        <AsyncPaginate
          // defaultValue={rendervalue()}
          {...field}
          defaultOptions={defaultOptions}
          cacheOptions={cacheOptions}
          placeholder={placeholder}
          value={field.value}
          loadOptions={loadOptions}
          disabled={disabled}
          menuPlacemen={menuPlacement}
          onChange={e => {
            if (e) {
              if (selectOnChange) {
                selectOnChange(e);
              } else {
                helpers.setValue(e);
              }
            } else {
              helpers.setValue(e);
            }
          }}
          isClearable={isClearable}
          menuPlacement={menuPlacement}
          isDisabled={disabled}
        />
      )}
      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
    </div>
  );
};

export default AsyncSelectField;
