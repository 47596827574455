import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    /* height: 100%;
    width: 100%; */
  }

  body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  #root {
    /* min-height: 100%;
    min-width: 100%; */
  }

  p,
  label {
    font-family: Georgia, Times, 'Times New Roman', serif;
    line-height: 1.5em;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .object-fit-contain {
    object-fit: contain;
  }

  .clear-btn {
    padding: 0px;
    border: 0px;
    background-color: transparent;
  }
`;
