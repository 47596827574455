import { TimeStamp } from '.';
import { Order } from './Order';

export interface Coupon extends TimeStamp {
  amount: number | null;
  couponCode: string;
  couponid: number;
  discountPercentage: number | null;
  endDate: string;
  mininumPurchaseRequired: number;
  startDate: string;
  description: string;
  RedeemedCount: number;
  UsedCount: number;
  canUseWithDiscount?: boolean;
  remarks: null | string;
}

export interface UserCoupon extends Coupon, TimeStamp {
  Orders: Order[];
  amount: null | number;
  canUseWithDiscount: boolean;
  couponCode: string;
  couponid: number;
  description: string;
  discountPercentage: number;
  endDate: string;
  mininumPurchaseRequired: number;
  startDate: string;
  status: string;
  userCouponid: number;
  userid: number;
}

export enum CouponStatus {
  'Invalid' = '過期',
  'Active' = '有效',
}
