import React from 'react';
import clsx from 'clsx';
import _ from 'lodash';

import Select from 'react-select';

import { Label } from 'reactstrap';
import { GroupedOption, OptionItems } from 'app/models';
import { FieldHookConfig, useField } from 'formik';

interface OtherProps {
  label?: string;
  horizontal?: boolean;
  placeholder?: string;
  groupedOptions: GroupedOption[];
  onChange?: (e) => void;
  isClearable?: boolean;
  disabled?: boolean;
  menuPlacement?: 'top' | 'bottom' | 'auto';
  name: string;
}

const GroupedSelectField = (props: OtherProps & FieldHookConfig<string>) => {
  const [field, , helpers] = useField(props);
  const {
    label,
    placeholder,
    groupedOptions,
    onChange,
    isClearable,
    disabled,
    menuPlacement,
  } = props;

  const rendervalue = () => {
    let tempArr: OptionItems[] = [];
    groupedOptions.forEach(group => {
      tempArr = tempArr.concat(group.options);
    });
    const index = _.findIndex(tempArr, option => option.value === field.value);
    if (index > -1) {
      return tempArr[index];
    }
    return null;
  };

  return (
    <div className={clsx('mb-2', 'form-group')}>
      {label ? <Label>{label}</Label> : null}
      <Select
        defaultValue={rendervalue()}
        isMulti={false}
        placeholder={placeholder}
        options={groupedOptions}
        classNamePrefix="select2-selection"
        {...field}
        value={rendervalue()}
        onChange={e => {
          if (onChange) {
            onChange(e.value);
          } else {
            if (e) {
              helpers.setValue(e.value);
            } else {
              helpers.setValue('');
            }
          }
        }}
        isClearable={isClearable}
        isDisabled={disabled}
        menuPlacement={menuPlacement || 'auto'}
        styles={{
          menuPortal: base => ({
            ...base,
            zIndex: 9999,
          }),
        }}
      />
    </div>
  );
};

export default GroupedSelectField;
