import { TimeStamp } from './Common';

export interface Notification extends TimeStamp {
  content: string;
  customNotificationid: number;
  image: null | string;
  orderid: null | number;
  promotionid: null | number;
  targetGroup: NotificationTargetGroupType;
  title: string;
  userid: null | number;
  isSent: boolean;
  scheduledDate: string;
}

export type NotificationTargetGroupType = 'Member' | 'Public' | 'VIP' | 'VVIP';

export enum NotificationTargetGroup {
  'Member' = '銀級 + 金級會員',
  'Public' = '已下載APP的用戶',
  'VIP' = '銀級會員',
  'VVIP' = '金級會員',
}
