import React from 'react';

interface TableCountProps {
  count: number | string;
  label: string;
}

function TableCount({ count, label }: TableCountProps) {
  return (
    <div className="d-flex flex-row align-items-center justify-content-end">
      <div className="text-dark font-weight-bold" style={{ fontWeight: 700 }}>
        {label}
      </div>
      <div className="font-size-24 text-muted ms-2">{count}</div>
    </div>
  );
}

export default TableCount;
