import Axios from './';
import { ListResult, Admin, Filter } from '../models';

/**
* * Create Admin (https://cmhk-wine-dev.appicidea.com/doc/#/Admin/post_v1_admin)
+*/

export interface CreateAdminParams {
  loginName: string;
  password: string;
  fullName: string;
}

export const createNewAdmin = async (para: CreateAdminParams) => {
  const { data } = await Axios.post<Admin>('/admin', para);
  return data;
};

/**
* * get Admin List (https://cmhk-wine-dev.appicidea.com/doc/#/Admin/get_v1_admin)
+*/

export interface GetAdminListParams extends Filter {}

export const getAdminList = async (params: GetAdminListParams) => {
  const { data } = await Axios.get<ListResult<Admin>>('/admin', {
    params,
  });
  return data;
};

/**
* * get Admin List (https://cmhk-wine-dev.appicidea.com/doc/#/Admin/post_v1_admin_login)
+*/

export interface AdminLoginParams {
  loginname: string;
  password: string;
}

export const adminLogin = async (para: AdminLoginParams) => {
  const { data } = await Axios.post<string>('/admin/login', para);
  return data;
};

/**
* * get One Admin (https://cmhk-wine-dev.appicidea.com/doc/#/Admin/get_v1_admin__adminid_)
+*/

export const getSingleAdmin = async (adminid: string) => {
  const { data } = await Axios.get<Admin>(`/admin/${adminid}`);
  return data;
};

/**
* * update One Admin (https://cmhk-wine-dev.appicidea.com/doc/#/Admin/patch_v1_admin__adminid_)
+*/

export interface UpdateAdminParams {
  fullName?: string;
}

export const updateAdmin = async (adminid: number, para: UpdateAdminParams) => {
  const { data } = await Axios.patch<null>(`/admin/${adminid}`, para);
  return data;
};

export const updateAdminAvatar = async (
  adminid: number,
  para: { avatar: string },
) => {
  const { data } = await Axios.patch<null>(`/admin/${adminid}`, para);
  return data;
};

/**
* * delete One Admin (https://cmhk-wine-dev.appicidea.com/doc/#/Admin/delete_v1_admin__adminid_)
+*/

export const deleteAdmin = async (adminid: number) => {
  const { data } = await Axios.delete<string>(`/admin/${adminid}`);
  return data;
};

/**
* * rest Admin password (https://cmhk-wine-dev.appicidea.com/doc/#/Admin/post_v1_admin__adminid__resetPassword)
+*/

export const resetAdminPassword = async (
  adminid: number,
  para: { password?: string },
) => {
  const { data } = await Axios.post<string>(
    `/admin/${adminid}/resetPassword`,
    para,
  );
  return data;
};

/**
* * get Admin profile (https://cmhk-wine-dev.appicidea.com/doc/#/Admin/get_v1_admin_profile)
+*/

export const getProfile = async () => {
  const { data } = await Axios.get<ListResult<Admin>>('/admin/profile');
  return data;
};

export const logout = async () => {
  const { data } = await Axios.post('/logout');
  return data;
};
