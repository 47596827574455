import React from 'react';
import { useField, FieldHookConfig } from 'formik';
import clsx from 'clsx';
import _ from 'lodash';

import CreatableSelect from 'react-select/creatable';

import { Label } from 'reactstrap';
import { OptionItems } from 'app/models';

interface OtherProps {
  label: string;
  horizontal?: boolean;
  placeholder?: string;
  options: OptionItems[];
  onChange?: (str: any) => void;
  isClearable?: boolean;
  disabled?: boolean;
  formatCreateLabel?: (str) => React.ReactNode;
}

const CreatableSelectField = (props: OtherProps & FieldHookConfig<any>) => {
  const [field, meta, helpers] = useField(props);
  const {
    label,
    placeholder,
    options,
    onChange,
    isClearable,
    disabled,
    formatCreateLabel,
  } = props;
  const rendervalue = () => {
    const index = _.findIndex(options, option => option.value === field.value);
    if (index > -1) {
      return options[index];
    }
    return null;
  };
  return (
    <div
      className={clsx(
        'mb-2',
        'form-group',
        meta.touched && meta.error && 'text-danger',
      )}
      style={{ height: 85 }}
    >
      {label ? <Label>{props.label}</Label> : null}
      <CreatableSelect
        defaultValue={rendervalue()}
        isMulti={false}
        placeholder={placeholder}
        options={options}
        classNamePrefix="select2-selection"
        formatCreateLabel={formatCreateLabel}
        {...field}
        value={rendervalue()}
        onChange={e => {
          if (onChange) {
            onChange(e.value);
          } else {
            if (e) {
              helpers.setValue(e.value);
            } else {
              helpers.setValue('');
            }
          }
        }}
        isClearable={isClearable}
        isDisabled={disabled}
      />
      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
    </div>
  );
};

export default CreatableSelectField;
