/**
 *
 * Maintenance
 *
 */

import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import MetaTags from 'react-meta-tags';
import { toast } from 'react-toastify';
import { changePreloader } from 'store/Layout';
import moment from 'moment';

import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Button,
  CardTitle,
  Container,
} from 'reactstrap';

import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { DateField, InputField } from 'app/components/Form';
import Breadcrumbs from 'app/components/Common/Breadcrumb2';
import { APP_NAME } from 'app/config';

// add service
import {
  createMaintenace,
  updateOneMaintenance,
  deleteOneMaintenance,
  getMaintenance,
} from 'app/services/MaintenanceService';
import { MaintenanceParams } from 'app/models';

// form

// form schema
const Schema = Yup.object().shape({
  title: Yup.string().nullable().required('必填項目'),
  startDate: Yup.date().nullable().required('必填項目'),
  endDate: Yup.date().nullable().required('必填項目'),
  startDateTime: Yup.string().nullable().required('必填項目'),
  endDateTime: Yup.string()
    .nullable()
    .test('validateEndTime', '結束時間不可早於開始時間', function (
      endDateTime,
    ): boolean {
      const { startDate, endDate, startDateTime } = this.parent;
      if (startDate && endDate && startDateTime && endDateTime) {
        const formatedStartDate =
          moment(startDate).format('YYYY-MM-DD') + ' ' + startDateTime;
        const fromatedEndDate =
          moment(endDate).format('YYYY-MM-DD') + ' ' + endDateTime;
        return !moment(fromatedEndDate, 'YYYY-MM-DD HH:mm').isBefore(
          moment(formatedStartDate, 'YYYY-MM-DD HH:mm'),
        );
      }
      return true;
    })
    .required('必填項目'),
});

// form type
type FormItem = {
  title: string;
  startDate: null | Date[];
  endDate: null | Date[];
  startDateTime: string | null;
  endDateTime: string | null;
};

// form initialValues
const FORM_ITEM = {
  title: '',
  startDate: null,
  endDate: null,
  startDateTime: '',
  endDateTime: '',
};

export const MaintenanceFormPage = () => {
  const dispatch = useDispatch();
  const { isPreloader } = useSelector(
    (rootState: RootState) => rootState.layout,
  );

  const [maintenanceId, setMaintenanceId] = useState<number | null>(null);
  const [form, setForm] = useState<FormItem>(FORM_ITEM);

  const [loading, setLoading] = useState<boolean>(false);
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);

  const initForm = useCallback(async () => {
    dispatch(changePreloader(true));
    // update form: get form data by id
    try {
      const res = await getMaintenance();
      const { rows } = res;
      if (rows.length) {
        const { maintenanceModeid, title, startTime, endTime } = rows[0];
        setMaintenanceId(maintenanceModeid);
        setForm({
          title,
          startDate: startTime ? [moment(startTime).toDate()] : null,
          endDate: endTime ? [moment(endTime).toDate()] : null,
          startDateTime: startTime ? moment(startTime).format('HH:mm') : null,
          endDateTime: endTime ? moment(endTime).format('HH:mm') : null,
        });
      } else {
        setMaintenanceId(null);
        setForm({
          title: '',
          startDate: null,
          endDate: null,
          startDateTime: '',
          endDateTime: '',
        });
      }
      dispatch(changePreloader(false));
    } catch (err) {
      dispatch(changePreloader(false));
      console.log(err);
      toast.warning('請檢查你的網絡。');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initForm();
  }, [initForm]);

  const formatData = (values: FormItem): MaintenanceParams => {
    const { title, startDate, startDateTime, endDate, endDateTime } = values;
    const formatedStartDate =
      moment(startDate![0]).format('YYYY-MM-DD') + ' ' + startDateTime;
    const fromatedEndDate =
      moment(endDate![0]).format('YYYY-MM-DD') + ' ' + endDateTime;
    return {
      title,
      startTime: moment(formatedStartDate, 'YYYY-MM-DD HH:mm').toISOString(),
      endTime: moment(fromatedEndDate, 'YYYY-MM-DD HH:mm').toISOString(),
    };
  };

  // create
  const createOneMaintenance = async (
    values: FormItem,
    actions: FormikHelpers<FormItem>,
  ) => {
    setLoading(true);
    const data = formatData(values);
    try {
      await createMaintenace(data);
      setLoading(false);
      toast.success('新增成功。');
      initForm();
    } catch (err: any) {
      if (err.response && err.response.status === 409) {
        // actions.setErrors({ loginName: '名稱已被註冊' });
      }
      setLoading(false);
      toast.warning('新增失敗，請重試。');
    }
  };

  // update
  const updateMaintenance = async (values: FormItem) => {
    setLoading(true);
    const data = formatData(values);
    try {
      await updateOneMaintenance(maintenanceId!, data);
      setLoading(false);
      toast.success('編輯維修模式成功。');
      initForm();
    } catch (err) {
      setLoading(false);
      toast.warning('編輯維修模式失敗，請重試。');
      console.log(err);
    }
  };

  // form submit
  const onSubmit = async (
    values: FormItem,
    actions: FormikHelpers<FormItem>,
  ) => {
    if (maintenanceId) {
      updateMaintenance(values);
    } else {
      createOneMaintenance(values, actions);
    }
  };

  const deleteConfirm = async () => {
    setLoading(false);
    try {
      await deleteOneMaintenance(maintenanceId!);
      toast.success('成功刪除');
      setLoading(false);
      setDeleteVisible(false);
      initForm();
    } catch (err) {
      toast.warning('刪除失敗，請重試。');
      setLoading(false);
      setDeleteVisible(false);
    }
  };

  const renderStatus = () => {
    const { startDate, startDateTime, endDate, endDateTime } = form;
    if (startDate && startDateTime && endDate && endDateTime) {
      const formatedStartDate =
        moment(startDate![0]).format('YYYY-MM-DD') + ' ' + startDateTime;
      const fromatedEndDate =
        moment(endDate![0]).format('YYYY-MM-DD') + ' ' + endDateTime;
      const startTime = moment(formatedStartDate, 'YYYY-MM-DD HH:mm');
      const endTime = moment(fromatedEndDate, 'YYYY-MM-DD HH:mm');
      const currentTime = moment();

      // 已完成

      if (currentTime.isAfter(endTime)) {
        return (
          <div className="text-danger">
            <i className="fas fa-exclamation-circle mr-2" />
            已完成
          </div>
        );
      }

      // 已開始

      if (currentTime.isAfter(startTime) && currentTime.isBefore(endTime)) {
        return (
          <div className="text-success">
            <i className="fas fa-check-circle mr-2" />
            已開始
          </div>
        );
      }

      // 未開始

      if (currentTime.isBefore(startTime)) {
        return (
          <div className="text-warning">
            <i className="fas fa-clock mr-2" />
            未開始
          </div>
        );
      }
    }

    return '-';
  };

  const TITLE = `管理系統 - 維修模式`;

  const BreadcrumbsOptions = [{ title: TITLE, path: '#' }];
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>
            {TITLE} | {APP_NAME}
          </title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="維修模式" breadcrumbItems={BreadcrumbsOptions} />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle>維修模式資料</CardTitle>
                  <div>
                    {maintenanceId ? (
                      <div className="d-flex flex-row">
                        <div>維修狀態:</div>
                        <div className="ms-1">{renderStatus()}</div>
                      </div>
                    ) : (
                      <div>未有維修紀錄</div>
                    )}
                  </div>
                  {!isPreloader ? (
                    <div className="p-2">
                      <Formik
                        initialValues={form}
                        validationSchema={Schema}
                        onSubmit={onSubmit}
                        enableReinitialize
                      >
                        {({ values, setFieldValue }) => (
                          <Form className="form-horizontal">
                            <Row>
                              <Col sm={12} className="mb-2">
                                <InputField
                                  name="title"
                                  label="標題"
                                  placeholder="輸入標題"
                                  type="text"
                                />
                              </Col>

                              <Col lg={6}>
                                <DateField
                                  name="startDate"
                                  label="開始日期"
                                  onChange={date => {
                                    setFieldValue('startDate', date);
                                    if (
                                      values.endDate &&
                                      values.endDate > date
                                    ) {
                                      setFieldValue('endDate', null);
                                    }
                                  }}
                                />
                              </Col>
                              <Col lg={6}>
                                <InputField
                                  name="startDateTime"
                                  label="開始時間"
                                  placeholder="輸入開始時間"
                                  type="time"
                                />
                              </Col>
                              <Col lg={6}>
                                <DateField
                                  name="endDate"
                                  label="結束日期"
                                  minDate={values.startDate}
                                />
                              </Col>
                              <Col lg={6}>
                                <InputField
                                  name="endDateTime"
                                  label="結束時間"
                                  placeholder="輸入結束時間"
                                  type="time"
                                />
                              </Col>
                            </Row>
                            <div className="mt-3 d-flex flex-row-reverse">
                              <Button
                                type="submit"
                                color="primary"
                                className="ms-1"
                                disabled={loading}
                              >
                                {loading ? (
                                  <i className="bx bx-loader-circle bx-spin" />
                                ) : (
                                  '提交'
                                )}
                              </Button>
                              {maintenanceId ? (
                                <Button
                                  type="button"
                                  color="danger"
                                  className="ms-1"
                                  onClick={() => setDeleteVisible(true)}
                                >
                                  刪除
                                </Button>
                              ) : null}
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={deleteVisible} scrollable={true}>
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              刪除
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => setDeleteVisible(false)}
              disabled={loading}
            ></button>
          </div>
          <div className="modal-body">
            <p>{`確定要刪除維修紀錄?`}</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => setDeleteVisible(false)}
              disabled={loading}
            >
              取消
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={deleteConfirm}
              disabled={loading}
            >
              {loading ? <i className="bx bx-loader-circle bx-spin" /> : '確定'}
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
};
