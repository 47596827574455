import Axios from './index';
import { FileResponse } from 'app/models';

/**
* * Upload and resize image file (https://cmhk-wine-dev.appicidea.com/doc/#/File/post_v1_file_image)
* @parama file string ($binary)
+*/

export const uploadImage = async fd => {
  const { data } = await Axios.post<FileResponse>('/file/image', fd, {
    timeout: 300000,
  });
  return data;
};

// export const uploadFile = async fd => {
//   const { data } = await Axios.post<FileResponse>('/file/file', fd);
//   return data;
// };
