import LOADING from 'app/assets/images/loading.gif';
import { IMAGE_SIZE } from 'app/config';
// import {
//   DisplayFileType,
//   DriveObjectType,
//   DriveType,
//   FileType,
// } from 'app/models';
import { uploadImage } from 'app/services/CommonService';
import { FieldHookConfig, useField } from 'formik';
import { useMemo, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { Label } from 'reactstrap';

import Dialog from 'app/components/Modal/Modal';
import LinkForm from './Html/LinkForm';
import { HTMLLinkType } from 'app/models';
import { getLinkName } from 'app/helpers/FormHelper';

import EmailIcon from 'app/assets/images/email.svg';
import PhoneIcon from 'app/assets/images/phone.svg';

// font size
const fontSizeArr = [
  '8px',
  '9px',
  '10px',
  '12px',
  '14px',
  '16px',
  '20px',
  '24px',
];

// line height
const lineHeightArr = [1, 1.2, 1.5, 2, 3];

const Parchment = Quill.import('parchment');
const LineStyle = new Parchment.Attributor.Style('lineHeight', 'line-height', {
  scope: Parchment.Scope.INLINE,
  whiteList: lineHeightArr,
});

const Size = Quill.import('attributors/style/size');
const Align = Quill.import('attributors/style/align');
Size.whitelist = fontSizeArr;
Quill.register(Size, true);
Quill.register(Align, true);
Quill.register(LineStyle, true);

// text and bg colors
const COLOR_ARR = [
  '#000000',
  '#222222',
  '#444444',
  '#666666',
  '#888888',
  '#cccccc',
  '#ffffff',

  '#48321D',
  '#995D21',
  '#C1925C',
  '#C47100',
  '#F6EBDC',
  '#F5B44F',
  '#F8F5F2',

  '#e74c3c',
  '#f39c12',
  '#fdda00',
  '#61a951',
  '#07a9fe',
  '#8e44ad',
  '#f32784',

  '#c0392b',
  '#d35400',
  '#f1c40f',
  '#16a085',
  '#003ba5',
  '#6F1E51',
  '#B53471',
];

export type Theme = 'light' | 'dark';
interface OtherProps {
  label: string;
  placeholder?: string;
  theme?: Theme;
}

let icons = Quill.import('ui/icons');
icons['emails'] = `<img src=${EmailIcon} width="18" height="18" />`;
icons['tel'] = `<img src=${PhoneIcon} width="16" height="14" />`;

const HTMLField = (props: OtherProps & FieldHookConfig<string>) => {
  const [field, meta, helpers] = useField(props);
  const { label, placeholder, theme = 'light', disabled = false } = props;

  const [linkVisibleType, setLinkVisibleType] = useState<HTMLLinkType | null>(
    null,
  );
  const quillRef = useRef<ReactQuill | null>(null);

  const imageHandler = () => {
    // get editor
    const editor = quillRef?.current?.getEditor();
    // @ts-ignore
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input?.files?.[0] || '';
      if (file && file.size > IMAGE_SIZE) {
        toast.success('圖片不可大於10MB');
        return;
      }
      const formData = new FormData();
      formData.append('file', file);
      if (editor) {
        const range = editor.getSelection();

        if (range) {
          editor.insertEmbed(range.index, 'image', LOADING);
          editor.setSelection(range);

          try {
            const res = await uploadImage(formData);
            editor.deleteText(range.index, 1);
            editor.insertEmbed(
              range.index,
              'image',
              `${res.url}/original/${res.fileName}`,
            );
          } catch (err) {
            editor.deleteText(range.index, 1);
            toast.warning('上載圖片失敗，請重試。');
          }
        }
      }
    };
  };

  // link button onClick
  const linkHandler = e => {
    // get editor
    const editor = quillRef?.current?.getEditor();
    if (editor) {
      const range = editor.getSelection();
      if (range?.length) {
        setLinkVisibleType('link');
      }
    }
  };

  // Video Handler
  // const videoHandler = () => {
  //   setVideoLinkVisible(true);
  // };

  const emailHandler = () => {
    const editor = quillRef?.current?.getEditor();
    if (editor) {
      const range = editor.getSelection();
      if (range?.length) {
        setLinkVisibleType('email');
      }
    }
  };

  const telHandler = () => {
    const editor = quillRef?.current?.getEditor();
    if (editor) {
      const range = editor.getSelection();
      if (range?.length) {
        setLinkVisibleType('tel');
      }
    }
  };

  const linkOnSubmit = ({ link }: { link: string }) => {
    const editor = quillRef?.current?.getEditor();
    if (editor) {
      editor.format('link', link);
      setLinkVisibleType(null);
    }
  };

  // quill modules
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ size: fontSizeArr }],
          [{ lineHeight: lineHeightArr }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ align: [] }],
          [{ color: COLOR_ARR }, { background: COLOR_ARR }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['link', 'image', 'video', 'emails', 'tel'],
        ],
        handlers: {
          image: imageHandler,
          link: linkHandler,
          // video: videoHandler,
          emails: emailHandler,
          tel: telHandler,
        },
      },
    }),
    [],
  );

  return (
    <>
      {label ? <Label>{props.label}</Label> : null}

      {/* html editor */}
      <div className={`html-editor ${theme}`}>
        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={field.value}
          modules={modules}
          onChange={html => {
            helpers.setValue(html.replaceAll('src="http://', 'src="https://'));
          }}
          placeholder={placeholder}
          scrollingContainer="#scrolling-container"
          readOnly={disabled}
        />
        {meta.touched && meta.error ? (
          <div className="text-danger">{meta.error}</div>
        ) : null}
      </div>

      {/* link form popup */}
      <Dialog
        visible={linkVisibleType ? true : false}
        size="md"
        title={`輸入${getLinkName(linkVisibleType)}`}
        onClose={() => setLinkVisibleType(null)}
      >
        <LinkForm linkOnSubmit={linkOnSubmit} linkType={linkVisibleType!} />
      </Dialog>
    </>
  );
};

export default HTMLField;
