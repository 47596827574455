/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useEffect, useRef, useCallback } from 'react';

// //Import Scrollbar
import SimpleBar from 'simplebar-react';

// MetisMenu
import MetisMenu from 'metismenujs';
import { useLocation } from 'react-router-dom';
import { NavLink, Link } from 'react-router-dom';

const SidebarContent = props => {
  const ref = useRef<any>();
  const location = useLocation();

  const activateParentDropdown = useCallback(item => {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = location.pathname;
    const initMenu = () => {
      new MetisMenu('#side-menu');
      let matchingMenuItem: any = null;
      const ul = document.getElementById('side-menu');
      const items = ul?.getElementsByTagName('a');
      if (items) {
        for (let i = 0; i < items.length; ++i) {
          const pathStr = pathName.split('/');
          let containPath = false;
          pathStr.forEach(str => {
            if (str && `/${str}` === items[i].pathname) {
              containPath = true;
            }
          });
          if (containPath) {
            matchingMenuItem = items[i];
            break;
          }
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.recalculate();
    }
  });

  const scrollElement = item => {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        if (ref && ref.current) {
          ref.current.getScrollElement().scrollTop = currentPosition - 300;
        }
      }
    }
  };

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: '100%', minHeight: '100%' }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">功能列表</li>
            <li>
              <Link className="has-arrow waves-effect" to="/#">
                <i className="bx bx-box"></i>
                <span>貨品管理</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink
                    to="/products"
                    className="waves-effect"
                    activeClassName="active mm-active"
                  >
                    <span>貨品列表</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/products-top-5"
                    activeClassName="active mm-active"
                  >
                    熱賣產品設定
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink
                to="/gifts"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="bx bx-gift"></i>
                <span>贈品</span>
              </NavLink>
            </li>

            <li>
              <Link className="has-arrow waves-effect" to="/#">
                <i className="bx bx-grid-alt"></i>
                <span>類別管理</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink
                    to="/category"
                    className="waves-effect"
                    activeClassName="active mm-active"
                  >
                    <span>類別</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/category-ordering"
                    activeClassName="active mm-active"
                  >
                    類別排序
                  </NavLink>
                </li>
              </ul>
            </li>

            <li>
              <Link className="has-arrow waves-effect" to="/#">
                <i className="bx bx-store"></i>
                <span>商店管理</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink
                    to="/shops"
                    className="waves-effect"
                    activeClassName="active mm-active"
                  >
                    <span>商店</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/shops-ordering"
                    activeClassName="active mm-active"
                  >
                    商店排序
                  </NavLink>
                </li>
              </ul>
            </li>

            <li>
              <NavLink
                to="/orders"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="bx bx-dollar-circle"></i>
                <span>訂單管理</span>
              </NavLink>
            </li>
            <li>
              <Link className="has-arrow waves-effect" to="/#">
                <i className="bx bxs-truck"></i>
                <span>運單管理</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink
                    to="/shipping/deliver"
                    activeClassName="active mm-active"
                  >
                    送貨上門
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/shipping/pickup"
                    activeClassName="active mm-active"
                  >
                    店舖取貨
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink
                to="/promotion"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="bx bx-calendar"></i>
                <span>推廣活動</span>
              </NavLink>
            </li>
            <li>
              <Link className="has-arrow waves-effect" to="/#">
                <i className="bx bxs-truck"></i>
                <span>橫額管理</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink to="/banners" activeClassName="active mm-active">
                    網站橫額管理
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/banners-sort"
                    activeClassName="active mm-active"
                  >
                    橫額排序
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/popup" activeClassName="active mm-active">
                    彈出廣告
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink
                to="/coupon"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="bx bxs-coupon"></i>
                <span>優惠券</span>
              </NavLink>
            </li>
            <li>
              <Link className="has-arrow waves-effect" to="/#">
                <i className="bx bxs-videos"></i>
                <span>影片管理</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <NavLink to="/video" activeClassName="active mm-active">
                    影片管理
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/video-section"
                    activeClassName="active mm-active"
                  >
                    影片分類
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/video-section-sort"
                    activeClassName="active mm-active"
                  >
                    影片分類排序
                  </NavLink>
                </li>
              </ul>
            </li>
            <li>
              <NavLink
                to="/messages"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="bx bx-mail-send"></i>
                <span>訊息</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admins"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="bx bx-user-circle"></i>
                <span>管理員</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/users"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="bx bx-user"></i>
                <span>會員</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/take-down"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="mdi mdi-archive-arrow-down"></i>
                <span>移除申請</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/notification"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="bx bxs-send"></i>
                <span>推送訊息</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/maintenance"
                className="waves-effect"
                activeClassName="active mm-active"
              >
                <i className="bx bx-cog"></i>
                <span>維修模式</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default SidebarContent;
