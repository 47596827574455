import React, { useEffect, useState, useCallback } from 'react';
import MetaTags from 'react-meta-tags';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Table, Pagination } from 'app/components/Table';

//Import Breadcrumb
import Breadcrumbs from 'app/components/Common/Breadcrumb2';

import UserDetailColumns from './UserDetailColumns';
import { GetShopListParams } from 'app/services/ShopServices';
import { APP_NAME, PAGE_SIZE } from 'app/config';
import { User, UserCoupon } from 'app/models';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
  deleteOneCouponInUser,
  getCouponsInUser,
  GetCouponsInUserParams,
  getOneUserProfile,
} from 'app/services/UserService';
import Dialog from 'app/components/Modal/Modal';
import { toast } from 'react-toastify';

interface userDetailQuery {
  userid?: string;
}

export const UserDetailPage = () => {
  const location = useLocation();
  const params = useParams<userDetailQuery>();
  const [user, setUser] = useState<User | null>(null);
  const [couponList, setCouponList] = useState<any[]>([]);
  const [filter, setFilter] = useState<GetCouponsInUserParams | null>(null);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [
    selectedUserCoupon,
    setSelectedUserCoupon,
  ] = useState<UserCoupon | null>(null);
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const getUser = useCallback(async () => {
    const { userid: userID } = params;
    if (filter && userID) {
      try {
        const userRes = await getOneUserProfile(userID);
        const couponRes = await getCouponsInUser(userID, filter);
        setUser(userRes);
        setCouponList(couponRes.rows);
        setCount(couponRes.count);
      } catch (err) {
        console.log(err);
      }
    }
  }, [filter, params]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const p = params.get('page');
    let tempFilter: GetShopListParams;
    if (filter) {
      tempFilter = { ...filter };
    } else {
      tempFilter = { limit: PAGE_SIZE, offset: 0 };
    }
    if (p) {
      tempFilter.offset = (parseInt(p) - 1) * PAGE_SIZE;
      setPage(parseInt(p));
    }
    setFilter(tempFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const deleteOnClick = (userCoupon: UserCoupon) => {
    setSelectedUserCoupon(userCoupon);
    setDeleteVisible(true);
  };

  const deleteConfirm = async () => {
    setLoading(true);
    try {
      await deleteOneCouponInUser(
        selectedUserCoupon?.userid!,
        selectedUserCoupon?.userCouponid!,
      );
      toast.success('成功刪除會員優惠券');
      setLoading(false);
      setDeleteVisible(false);
      getUser();
    } catch (err) {
      toast.warning('刪除會員優惠券失敗，請重試。');
      setLoading(false);
      setDeleteVisible(false);
    }
  };

  const TITLE = `會員優惠券紀錄`;
  const BreadcrumbsOptions = [
    { title: '會員', path: '/users' },
    { title: '會員列表', path: '/users' },
    { title: TITLE, path: '#' },
  ];

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>會員優惠券紀錄 | {APP_NAME}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title={TITLE} breadcrumbItems={BreadcrumbsOptions} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <>
                    <Row className="mb-2">
                      <Col xl={3}>
                        <div>
                          <h5>{user?.fullName}</h5>
                          <div className="text-muted">
                            {user?.phoneNumber || '-'}
                          </div>
                        </div>
                      </Col>
                      <Col
                        xl={9}
                        className="d-flex justify-content-end align-items-end"
                      >
                        <div>
                          <div className="d-flex flex-row align-items-center justify-content-end">
                            <div
                              className="text-dark font-weight-bold"
                              style={{ fontWeight: 700 }}
                            >
                              優惠券數量
                            </div>
                            <div className="font-size-24 text-muted ms-2">
                              {count}
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <Table
                            columns={UserDetailColumns(deleteOnClick)}
                            keyField="userCouponid"
                            data={couponList || []}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-md-center mt-3">
                      <Col className="pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination">
                        <Pagination count={count} current={page} />
                      </Col>
                    </Row>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Dialog
          visible={deleteVisible}
          title="刪除優惠券"
          onClose={() => {
            setDeleteVisible(false);
            setSelectedUserCoupon(null);
          }}
          loading={loading}
          onConfirm={deleteConfirm}
        >
          <p>{`確定要刪除會員${user?.fullName}的優惠券（編號: ${selectedUserCoupon?.userCouponid}）?`}</p>
        </Dialog>
      </div>
    </>
  );
};
