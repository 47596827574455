import { IMAGE_URL } from 'app/config';

export const getImageURL = (imgSrc: string, size?: number | 'original') => {
  if (imgSrc && imgSrc.includes('https')) {
    return imgSrc;
  } else if (imgSrc) {
    return `${IMAGE_URL}/${size ? size : '320'}/${imgSrc}`;
  }
  return '';
};

export const formatPrice = (price: number) => {
  if (price || price === 0) {
    const dollarUS = Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return dollarUS.format(price);
  }
  return '-';
};

export const convertToServerPrice = (price: number) => {
  if (price) {
    return price * 100;
  }
  return price;
};

export const convertToClientPrice = (price: number) => {
  if (price) {
    return price / 100;
  }
  return price;
};
