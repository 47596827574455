import React from 'react';
import { Table } from 'reactstrap';

interface Props {
  columns: any[];
  data: any[];
  keyField: string;
  noWrap?: boolean;
}

function CustomTable({ columns, data, keyField, noWrap }: Props) {
  return (
    <Table className="table align-middle table-nowrap table-hover">
      <thead className="table-light">
        <tr>
          {columns.map(column => (
            <th key={column.text}>{column.text}</th>
          ))}
        </tr>
      </thead>
      {data.length > 0 ? (
        <tbody>
          {data.map(item => {
            return (
              <tr key={item[keyField]}>
                {columns.map(column => {
                  const trKey = `${column.text + ''}-${item[keyField]}`;
                  if (column.formatter) {
                    return (
                      <td
                        key={trKey}
                        style={noWrap ? { whiteSpace: 'pre-line' } : {}}
                      >
                        {column.formatter(item)}
                      </td>
                    );
                  }
                  return <td key={trKey}>{item[column.dataField]}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      ) : null}
    </Table>
  );
}

export default CustomTable;
