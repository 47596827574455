import { ListResult } from 'app/models';
import Axios from './';
import { Maintenance, MaintenanceParams } from 'app/models';

/**
* * get maintenance (https://yyt.appicidea.com/doc/#/Maintenance/get_v1_maintenance_)
+*/

export const getMaintenance = async () => {
  const { data } = await Axios.get<ListResult<Maintenance>>('/maintenance', {
    params: { limit: 1, offset: 0 },
  });
  return data;
};

/**
* * update maintenance (https://yyt.appicidea.com/doc/#/Maintenance/patch_v1_maintenance_current)
+*/

export const updateOneMaintenance = async (
  maintenanceModeid: string | number,
  params: MaintenanceParams,
) => {
  const { data } = await Axios.patch<any>(
    `/maintenance/${maintenanceModeid}`,
    params,
  );
  return data;
};

/**
* * delete maintenance (https://yyt.appicidea.com/doc/#/Maintenance/delete_v1_maintenance__maintenanceModeid_)
+*/

export const deleteOneMaintenance = async (
  maintenanceModeid: string | number,
) => {
  const { data } = await Axios.delete<any>(`/maintenance/${maintenanceModeid}`);
  return data;
};

/**
* * create maintenance (https://yyt.appicidea.com/doc/#/Maintenance/post_v1_maintenance_)
+*/

export const createMaintenace = async (params: MaintenanceParams) => {
  const { data } = await Axios.post<any>(`/maintenance`, params);
  return data;
};
